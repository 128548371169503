"use client";

import { useQuery } from "@tanstack/react-query";

import { useTenantStore } from "@repo/libs/stores/useTenantStore";
import { getEnabledPaymentMethods } from "@repo/libs/utils";

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL?.endsWith("/")
  ? process.env.NEXT_PUBLIC_API_BASE_URL
  : `${process.env.NEXT_PUBLIC_API_BASE_URL}/`;

interface OnboardingSettings {
  [key: string]: string;
}

export function useOnboardingData() {
  const tenant = useTenantStore.getState().tenant;

  const {
    data: configuration,
    isLoading,
    error,
  } = useQuery<{ settings: OnboardingSettings }>({
    queryKey: ["configuration", tenant],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      console.log("tenant from useOnboardingData", tenant);
      if (tenant === "null") {
        throw new Error("Tenant is not set");
      }
      const response = await fetch(
        `${BASE_URL}api/app/setting-manager/onboarding`,
        {
          headers: {
            __tenant: tenant,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Failed to fetch configuration");
      }
      return response.json();
    },
  });

  const settings = configuration?.settings;

  return {
    settings,
    isLoading,
    error,
    logoUrl:
      settings?.["Platform.Invoice.LogoUrl"] ??
      "https://cdn.rekaz.io/assets/placeholder.webp",
    enabledPaymentMethods: getEnabledPaymentMethods(settings) || [],
    businessName: settings?.["Platform.Invoice.CommercialName"] ?? "",
    businessDescription: settings?.["Platform.Business.Description"] ?? "",
    tenantDomain: settings?.["Platform.PublicDomain"] ?? "/",
    removeMadeByRekaz: isLoading
      ? undefined
      : settings?.["Platform.RemoveMadeByRekaz"] === "True",
    isWhatsappButtonEnabled:
      settings?.["Platform.Contact.EnableWhatsappButton"] === "True",
    socials: {
      email: settings?.["Platform.Contact.Email"],
      phone: settings?.["Platform.Contact.MobileNumber"],
      snapchat: settings?.["Platform.Contact.Snapchat"],
      twitter: settings?.["Platform.Contact.Twitter"],
      instagram: settings?.["Platform.Contact.Instagram"],
      whatsapp: settings?.["Platform.Contact.Whatsapp"],
      tiktok: settings?.["Platform.Contact.Tiktok"],
    },
  };
}

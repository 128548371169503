"use client";

import React, { useMemo } from "react";

import dynamic from "next/dynamic";

import {
  ComponentConfigurationDto,
  PageDto,
  WebsiteConfigurationDto,
} from "./lib/configuration-dtos";
import { CommonComponentProps } from "./lib/types";

// something better later on
// is it event needed
const componentCache: Record<string, React.ComponentType<any>> = {};

// breaks if not exported by default for some odd reason i don't want to discover
export default function RenderComponent({
  componentConfig,
  websiteConfig,
  currentPage,
  extraProps,
}: {
  componentConfig: ComponentConfigurationDto;
  websiteConfig: WebsiteConfigurationDto;
  currentPage: PageDto;
  extraProps?: any;
}) {
  const Component = useMemo(() => {
    const name = componentConfig.name!;
    if (componentCache[name]) {
      return componentCache[name];
    }
    const Component = dynamic(
      () => import(`./sections/${componentConfig.importPath}`),
    );
    componentCache[name] = Component;
    return Component;
  }, [componentConfig]);

  const params: CommonComponentProps = {
    websiteConfig,
    currentPage,
    ...componentConfig.properties,
    ...extraProps,
  };

  return <Component {...(params as any)} />;
}

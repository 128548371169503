"use client";

import React from "react";

import Link from "next/link";

import { Whatsapp } from "@repo/ui/components/icons";

import { useOnboardingData } from "@website-toolkit/hooks/useOnboardingData";
import { getSocialHref } from "@website-toolkit/lib/socialsUtils";

export default function WhatsappButton() {
  const { isWhatsappButtonEnabled, socials } = useOnboardingData();

  const { whatsapp: phoneNumber } = socials;

  if (!isWhatsappButtonEnabled) {
    return null;
  }

  return (
    <div className="fixed bottom-3 z-50 size-12 rounded-full bg-green-500 p-2 text-white hover:bg-green-400 ltr:right-3 rtl:left-3">
      <Link
        id="whatsapp"
        href={getSocialHref("whatsapp", phoneNumber || "")}
        className="text-white"
      >
        <Whatsapp className="fill-current" />
      </Link>
    </div>
  );
}

"use client";

import { cn } from "@repo/libs/utils";

import { useOnboardingData } from "@website-toolkit/hooks/useOnboardingData";

interface TradeMarkProps {
  noFooter?: boolean;
}

const TradeMark = ({ noFooter }: TradeMarkProps) => {
  const { removeMadeByRekaz = true } = useOnboardingData();

  return (
    <p
      className={cn(
        "text-center text-sm",
        noFooter ? "bg-theme-background text-theme-text py-3" : "mt-4 sm:mt-0",
      )}
    >
      {!removeMadeByRekaz && (
        <a
          href="https://rekaz.io?utm_source=rekazsites&utm_medium=rekazsites&utm_campaign=madebyrekaz"
          target="_blank"
          className="inline-block transition-transform duration-300 hover:scale-105"
        >
          صنعت بكل محبة ❤️ بتقنيات ركاز
        </a>
      )}
    </p>
  );
};

export default TradeMark;

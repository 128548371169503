import {
  RemixiconComponentType,
  RiInstagramFill,
  RiMailOpenFill,
  RiPhoneFill,
  RiSnapchatFill,
  RiTiktokFill,
  RiTwitterFill,
  RiWhatsappFill,
} from "@remixicon/react";

export type SocialKey =
  | "email"
  | "phone"
  | "snapchat"
  | "twitter"
  | "instagram"
  | "whatsapp"
  | "tiktok";

export const iconMap: Record<SocialKey, RemixiconComponentType> = {
  email: RiMailOpenFill,
  phone: RiPhoneFill,
  snapchat: RiSnapchatFill,
  twitter: RiTwitterFill,
  instagram: RiInstagramFill,
  whatsapp: RiWhatsappFill,
  tiktok: RiTiktokFill,
};

export function getSocialHref(key: SocialKey, value: string): string {
  switch (key) {
    case "email":
      return `mailto:${value}`;
    case "phone":
      return `tel:${value}`;
    case "whatsapp":
      const valueWithCountryCode = value.startsWith("0")
        ? `966${value.slice(1)}`
        : value;
      return `https://wa.me/${valueWithCountryCode}`;
    case "twitter":
      if (value.startsWith("http")) return value;
      return `https://twitter.com/${value}`;
    case "instagram":
      if (value.startsWith("http")) return value;
      return `https://instagram.com/${value}`;
    case "snapchat":
      if (value.startsWith("http")) return value;
      return `https://snapchat.com/add/${value}`;
    case "tiktok":
      if (value.startsWith("http")) return value;
      return `https://tiktok.com/@${value}`;
    default:
      return value;
  }
}
